<script lang="ts" setup>
import type { TabData } from '~/composables/useTabs'

const props = defineProps<{
  tab: TabData
  active: boolean
  index: number
}>()

const { tabs, activeTabIndex } = storeToRefs(useTabs())
const { updateTabLabel } = useTabs()

const { allConfigs } = storeToRefs(useCaseConfig())
const { removeCaseConfig } = useCaseConfig()

const editMode = ref<boolean>(false)
const editValue = ref<string>(props.tab.label)

const titleInputRef = ref<HTMLInputElement>()

async function enableEditMode () {
  editMode.value = true
  editValue.value = props.tab.label
}

function saveTitle () {
  if (!editValue.value)
    return
  editMode.value = false
  updateTabLabel(props.index, editValue.value)
}
</script>

<template>
  <div
    flex items-center justify-between pl-16px pr-8px h-48px w-148px rounded-t-8px border="1px app-stroke" border-b="0" :class="[active ? 'bg-white' : 'bg-app-bg-header']"
    @click="activeTabIndex = tabs.indexOf(tab)" @dblclick="enableEditMode()"
  >
    <input v-if="editMode" ref="titleInputRef" v-model="editValue" :onload="titleInputRef?.focus()" border="1px black" max-w-102px text-14px font-bold @focusout="editMode = false" @keyup.enter="saveTitle()">
    <p v-else text="14px black" font-700 whitespace-nowrap>
      {{ tab.label }}
    </p>
    <UnoIcon v-if="allConfigs.length > 1" i-heroicons-solid-x-circle pt--8px text="16px app-text-icon" cursor-pointer @click.stop="removeCaseConfig(tab.config.id!)" />
  </div>
</template>
