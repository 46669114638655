<script lang="ts" setup>
import { promiseTimeout, useClipboard } from '@vueuse/core'

defineProps<{
  showTabs?: boolean
}>()

const { user } = storeToRefs(useUser())
const { logout } = useAuth()
const { tabs, activeTabIndex } = storeToRefs(useTabs())
const { addCaseConfig } = useCaseConfig()
const { openLogin, openCart } = useModals()
const { showAlert } = useAlert()

const tabsContainer = ref<HTMLDivElement>()

async function addTab () {
  addCaseConfig()
  if (tabs.value.length > 5 && tabsContainer.value) {
    await promiseTimeout(100)
    tabsContainer.value.scrollLeft = tabsContainer.value.scrollWidth - tabsContainer.value.clientWidth
  }
}

function copyToClipboard (value: string) {
  const { copy, copied, isSupported } = useClipboard({ source: value })
  if (!isSupported)
    return
  copy()
  if (copied) {
    showAlert({
      intent: 'success',
      title: 'Zkopírováno do schránky',
      seconds: 3,
    })
  }
}
</script>

<template>
  <div h-auto>
    <div
      flex h-64px bg-app-bg-header px-24px border-b="1px app-stroke" header-shadow
      :class="{ 'justify-between': !showTabs }"
    >
      <NuxtLink to="/" focus:outline-none flex items-center gap-8px min-w="74px lg:320px">
        <NuxtImg width="78" height="40" src="/logo.svg" />
        <h1 hidden lg:block text="14px black" italic font-600>
          KONFIGURÁTOR
        </h1>
      </NuxtLink>
      <div flex items-center justify="end lg:between" gap-32px :class="{ 'w-full': showTabs }">
        <div flex gap-16px items-end w-full max-w="256px md:380px 2xl:832px" h-64px>
          <div v-if="showTabs" ref="tabsContainer" v-auto-animate :class="[tabs.length > 5 ? 'overlow-x-auto' : 'overflow-x-hidden']" flex items-end overflow-y-hidden hidden lg:flex gap-8px un-scrollbar="~ w-4px track-color-app-stroke thumb-color-app-blue rounded radius-10px">
            <HeaderTab
              v-for="(tab, index) in tabs" :key="tab.config.id" :tab="tab" :active="activeTabIndex === index"
              :index="index" :class="{ 'border-b-1px': activeTabIndex !== index }" cursor-pointer
            />
          </div>
          <div v-if="showTabs" h-48px flex items-center>
            <button text-btn focus:outline-none @click="addTab()">
              + Nový projekt
            </button>
          </div>
        </div>
        <div flex items-center justify-end>
          <div flex items-center gap="20px lg:24px" mr="16px lg:24px">
            <div cursor-pointer hidden lg:flex items-center gap-8px @click="copyToClipboard('+420734853095')">
              <UnoIcon i-heroicons-solid-phone text="24px app-text-icon" />
              <p hidden xl:block text="14px app-text-light" font-500 leading-16px>
                +420 734 853 095
              </p>
            </div>
            <div hidden lg:flex items-center gap-8px cursor-pointer @click="copyToClipboard('info@kmfire.cz')">
              <UnoIcon i-heroicons-solid-at-symbol text="24px app-text-icon" />
              <p hidden xl:block text="14px app-text-light" font-500 leading-16px>
                info@kmfire.cz
              </p>
            </div>
            <NuxtLink
              focus:outline-none
              to="https://firebasestorage.googleapis.com/v0/b/kmfire-e4742.appspot.com/o/KMFIRE%20manual.pdf?alt=media"
              external target="_blank" flex items-center gap-8px
            >
              <UnoIcon i-heroicons-solid-question-mark-circle text="24px app-text-icon" />
              <p hidden xl:block text="14px app-text-light" font-500 leading-16px>
                Manuál
              </p>
            </NuxtLink>
            <div flex items-center gap-20px>
              <div hidden xl:block w-1px h-32px bg-app-stroke />
              <UnoIcon i-heroicons-solid-shopping-cart text="24px app-text-icon" cursor-pointer @click="openCart()" />
            </div>
          </div>
          <button v-if="!user" type="button" app-btn font-700 text="14px" leading-16px @click="openLogin()">
            PŘIHLÁSIT SE
          </button>
          <HeadlessMenu v-else v-slot="{ open }" as="div" class="relative">
            <div>
              <HeadlessMenuButton flex items-center gap-8px focus:outline-none>
                <div
                  flex items-center justify-center w-36px h-36px bg-app="blue/20" text-app-blue font-500
                  rounded-full
                >
                  {{
                    user.name.substring(0, 1).toUpperCase() }}{{ user.surname.substring(0, 1).toUpperCase() }}
                </div>
                <div text-14px font-500>
                  {{ user.name }} {{ user.surname }}
                </div>
                <UnoIcon
                  :class="[open ? 'i-heroicons-solid-chevron-up' : 'i-heroicons-solid-chevron-down']"
                  text="24px app-text-icon"
                />
              </HeadlessMenuButton>
            </div>
            <transition
              enter-active-class="transition ease-out duration-100"
              enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100"
              leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100"
              leave-to-class="transform opacity-0 scale-95"
            >
              <HeadlessMenuItems
                class="absolute right-0 z-10 mt-14px w-152px origin-top-right rounded-md rounded-t-0 shadow-t-0 bg-white card-shadow focus:outline-none ring-0"
              >
                <HeadlessMenuItem v-slot="{ active }">
                  <NuxtLink
                    focus:outline-none to="/admin/orders"
                    class="block px-4 py-2 text-sm text-gray-700  font-500" :class="[active ? 'bg-app-blue/10' : '']"
                  >
                    Objednávky
                  </NuxtLink>
                </HeadlessMenuItem>
                <HeadlessMenuItem v-slot="{ active }">
                  <NuxtLink
                    focus:outline-none to="/admin/company"
                    class="block px-4 py-2 text-sm text-gray-700 font-500" :class="[active ? 'bg-app-blue/10' : '']"
                  >
                    Údaje o firmě
                  </NuxtLink>
                </HeadlessMenuItem>
                <HeadlessMenuItem v-slot="{ active }">
                  <NuxtLink
                    focus:outline-none to="/admin/users" class="block px-4 py-2 text-sm text-gray-700 font-500"
                    :class="[active ? 'bg-app-blue/10' : '']"
                  >
                    Uživatelské účty
                  </NuxtLink>
                </HeadlessMenuItem>
                <HeadlessMenuItem v-if="user.isAdmin" v-slot="{ active }">
                  <NuxtLink
                    focus:outline-none to="/admin/customers"
                    class="block px-4 py-2 text-sm text-gray-700 font-500" :class="[active ? 'bg-app-blue/10' : '']"
                  >
                    Správa zákazníků
                  </NuxtLink>
                </HeadlessMenuItem>
                <HeadlessMenuItem v-if="user.isAdmin" v-slot="{ active }">
                  <NuxtLink
                    focus:outline-none to="/admin/sales" class="block px-4 py-2 text-sm text-gray-700 font-500"
                    :class="[active ? 'bg-app-blue/10' : '']"
                  >
                    Slevové hladiny
                  </NuxtLink>
                </HeadlessMenuItem>
                <HeadlessMenuItem v-slot="{ active }" class="border-t">
                  <div
                    focus:outline-none cursor-pointer
                    class="block px-4 py-2 text-sm text-gray-700 rounded-b-md font-500"
                    :class="[active ? 'bg-app-blue/10' : '']" @click="logout()"
                  >
                    Odhlásit se
                  </div>
                </HeadlessMenuItem>
              </HeadlessMenuItems>
            </transition>
          </HeadlessMenu>
        </div>
      </div>
    </div>
    <div flex justify-between items-center lg:hidden h-48px border-b="1px app-stroke" py-14px px-16px>
      <h1 text="14px black" font-700 leading-20px>
        Projekt 1
      </h1>
      <UnoIcon i-heroicons-solid-chevron-down text="24px black" />
    </div>
  </div>
</template>

<style scoped>
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* Scrollbar Track */
.scrollbar-hide {
  scrollbar-width: thin;
  /* For Firefox */
  scrollbar-color: transparent transparent;
  /* For Firefox */
}

/* Handle */
.scrollbar-hide::-webkit-scrollbar-thumb {
  background-color: transparent;
  /* For Chrome, Safari, and Opera */
}

/* Handle on hover */
.scrollbar-hide::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.2);
  /* For Chrome, Safari, and Opera */
}
</style>
